.App {
  display: flex;
  flex-direction: row;
  justify-content: center;
  justify-items: center;
}
.trackingNo{
  color: coral;
}
.cargo-status{
  padding: 0 !important;
  text-align: center;
  font-size: 20px;
}
.cargo-table{
  margin-top: 0rem;
}